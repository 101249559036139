<template>
    <div>
        <b-form-group label="Billeder">
            <b-form-file v-model="files" accept="image/*" multiple placeholder="Vælg filer..." @change="uploadFiles" />
        </b-form-group>

        <b-row>
            <b-col>
                <draggable v-model="images" class="cursor-move">
                    <div v-for="(item, key) in images" :key="key" class="position-relative d-inline-block ma-5" style="width: 150px;">
                        <b-img class="" thumbnail fluid :src="item.fileName" />

                        <b-button variant="gradient-danger" class="btn-icon rounded-circle position-absolute"
                            style="bottom: 0; right: 0;" @click="deleteFile(item)">
                            <feather-icon icon="Trash2Icon" />
                        </b-button>
                    </div>
                </draggable>
            </b-col>
        </b-row>
    </div>
</template>

<script>
    export default {
        components: {},
        props: {
            value: null,
            url: String,
            deleteUrl: String
        },
        data: () => ({
            files: []
        }),
        computed: {
            images: {
                get() {
                    return this.value
                },
                set(val) {
                    this.$emit('input', val)
                }
            }
        },
        created() { },
        mounted() { },
        methods: {
            uploadFiles(e) {
                var formData = new FormData()
                var imagefiles = e.target.files
                if (imagefiles && imagefiles.length > 0) {
                    imagefiles.forEach(x => {
                        formData.append("file", x)
                    })

                    this.$http.post(this.url, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }).then(x => {
                        this.files = []
                        e.target.value = ""

                        this.$emit("uploaded", x.data)
                    })
                }
            },
            deleteFile(item) {
                this.$http.post(this.deleteUrl, { path: item }).then(x => {
                    this.$emit("deleted")
                })
            }
        },
    }
</script>