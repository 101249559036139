import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import ability from '@/libs/acl/ability'

// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        userInit: false,
        user: null,

        stores: null,
        currentStore: null,

        paymentMethods: [
            { text: "Kreditkort", value: "Optipay" },
            { text: "Kontant", value: "Cash" },
            { text: "Bankoverførsel", value: "Banktransfer" },
            { text: "EAN", value: "EAN" }
        ],

        rounding: [
            { text: "½ kr.", value: 0 },
            { text: "1 kr.", value: 10 },
            { text: "5 kr.", value: 20 },
            { text: "10 kr.", value: 30 }
        ],

    },

    actions: {
        INIT_USER({ commit, dispatch }) {
            return new Promise((resolve, reject) => {
                axios.get("/api/user").then(x => {
                    commit("INIT_USER", x.data)
                    ability.update(x.data.acl)
                    resolve()
                }).catch(x => {
                    commit("INIT_USER", null)
                    resolve()
                })
            })
        },
        LOGIN({ commit, dispatch }, data) {
            commit("INIT_USER", data)
        },
        STORES({ commit, dispatch }) {
            return new Promise((resolve, reject) => {
                axios.get("/api/stores").then(x => {
                    commit("STORES", x.data)
                    resolve()
                })
            })
        },
        USERS({ commit, dispatch }) {
            return new Promise((resolve, reject) => {
                axios.get("/api/all-users").then(x => {
                    commit("USERS", x.data)
                    resolve()
                })
            })
        }
    },

    mutations: {
        INIT_USER(state, val) {
            state.userInit = true
            state.user = val
        },
        STORES(state, val) {
            state.stores = val
            state.currentStore = val.find(x => x.selected)
        },
        USERS(state, val) {
            state.users = val
        }
    },


    modules: {
        app,
        appConfig,
        verticalMenu,
    },
    strict: process.env.DEV,
})
