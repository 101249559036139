<template>
    <div id="app" class="h-100" :class="[skinClasses]">
        <component :is="layout">
            <router-view :key="$route.name + ($route.params.id || '')" />
        </component>
    </div>
</template>

<script>
    // This will be populated in `beforeCreate` hook
    import { $themeColors, $themeBreakpoints, $themeConfig } from '@themeConfig'
    import { provideToast } from 'vue-toastification/composition'
    import { watch } from '@vue/composition-api'
    import useAppConfig from '@core/app-config/useAppConfig'

    import { useWindowSize, useCssVar } from '@vueuse/core'

    import store from '@/store'

    const LayoutVertical = () => import('@/layouts/vertical/LayoutVertical.vue')
    const LayoutFull = () => import('@/layouts/full/LayoutFull.vue')

    export default {
        data: () => ({

        }),
        components: {
            // Layouts
            LayoutVertical,
            LayoutFull,
        },
        // ! We can move this computed: layout & contentLayoutType once we get to use Vue 3
        // Currently, router.currentRoute is not reactive and doesn't trigger any change
        computed: {
            layout() {
                if (!this.$route.name) {
                    return "layout-full"
                }

                if (this.$route.meta.layout !== 'full') return 'layout-vertical'
                return "layout-full"
            }
        },
        beforeCreate() {
            // Set colors in theme
            const colors = ['primary', 'secondary', 'success', 'info', 'warning', 'danger', 'light', 'dark']

            for (let i = 0, len = colors.length; i < len; i++) {
                $themeColors[colors[i]] = useCssVar(`--${colors[i]}`, document.documentElement).value.trim()
            }

            // Set Theme Breakpoints
            const breakpoints = ['xs', 'sm', 'md', 'lg', 'xl']

            for (let i = 0, len = breakpoints.length; i < len; i++) {
                $themeBreakpoints[breakpoints[i]] = Number(useCssVar(`--breakpoint-${breakpoints[i]}`, document.documentElement).value.slice(0, -2))
            }
        },
        setup() {
            const { skin, skinClasses } = useAppConfig()

            // If skin is dark when initialized => Add class to body
            if (skin.value === 'dark') document.body.classList.add('dark-layout')

            // Set Window Width in store
            store.commit('app/UPDATE_WINDOW_WIDTH', window.innerWidth)
            const { width: windowWidth } = useWindowSize()
            watch(windowWidth, val => {
                store.commit('app/UPDATE_WINDOW_WIDTH', val)
            })

            return {
                skinClasses
            }
        },
        mounted() {

        }
    }
</script>
